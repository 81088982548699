<template>
  <div id="information">
    <div class="top"></div>
    <div class="news wrapper">
      <div class="title">
        <router-link to="/dash">首页<span v-html="'&nbsp'"></span></router-link>
        <router-link to="/information"
          >-<span v-html="'&nbsp'"></span>资讯<span v-html="'&nbsp'"></span
        ></router-link>
        <a href="javascript:void(0)"
          >-<span v-html="'&nbsp'"></span>六省市整治住房租赁市场
          住房租赁立法亟须按下“快进键”</a
        >
      </div>
      <div class="content">
        <el-row>
          <el-col :span="18" class="left">
            <div class="news_title">
              <div class="newsDate">
                <div class="year">2020-10</div>
                <div class="day">09</div>
              </div>
              <div class="newsText">
                六省市整治住房租赁市场 住房租赁立法亟须按下“快进键”
              </div>
            </div>
            <div class="news_content">
              <p>
                据报道，近期北京、山西、深圳、南京、杭州、合肥等六省市对住房租赁市场进行集中整治，其中虚假房源、随意涨租、租金贷等成为整治重点。如北京对虚假房源零容忍，今年以来已查处房地产中介机构208家；山西通过部门联合执法，纠正和查处发布虚假房源信息；杭州、南京严查是否存在租金贷合同套嵌，大力整治强制或诱导使用“租金贷”支付租金乱象，等等。
              </p>
              <p>
                六省市集中整治住房租赁市场，也是对房地产市场供给侧进行改革与调整、优化与完善，不仅要让房地产市场秩序更加规范，促进房地产市场健康有序发展，也要通过供给侧供应水平的提高，让房地产市场不再因为购房比重太高、购房者太多而导致供需矛盾尖锐，影响房地产市场从调控转向长效管理。
              </p>
              <p>
                我国房地产市场经历了多年多轮调控，目前正进入由调控向长效管理转型的关键阶段。从当下的实际情况看，一些地方仍然存在着“一松房价就涨”的风险。今年上半年，由于一度传出“限购将退出”的消息，一些城市房价出现了快速上涨，尽管这与人口流动有一定关系，但主要还是缘于对楼市走势的盲目判断以及部分炒房者推波助澜。炒房者和住房投资者会利用“限购政策退出”“市场需求大”等说法，放大楼市供需矛盾，继而让刚需人群产生恐慌情绪，“配合”制造市场紧张气氛。
              </p>
              <p>
                因此，改善楼市供需关系，缓解供需矛盾，让刚需阶层不一定都要购房，让部分住房需求转向住房租赁市场，就成为一种理性而迫切的选择。如果租赁市场能够满足部分居民的住房需要，且成为长期租房需求，房地产市场的结构就能得到较大改善，购房压力就会大大减少，从而有效挤压楼市炒作空间。
              </p>
              <p>
                随着国家对发展住房租赁市场工作的日益重视，相关住房租赁市场政策陆续出台，住房租赁市场暴露的问题也日渐突出，如炒高租金、提供虚假房源信息等都是长期存在的顽疾。尽管问题不新鲜，但问题产生的后果可能会很严重，如果不有效控制好租金，不把房源信息把控好，不规范租房者的行为，就很难让住房租赁市场成为房地产市场的重要补充，不但难以提高租房在住房中的比重，减轻居民的购房压力，甚至反而会加剧居民购房的迫切性。
              </p>
              <p>
                在租金价格方面，相关数据也显示，疫情影响逐渐减弱。58同城、安居客数据显示，今年三季度，19个重点城市的平均租金为每月每平方米41.1元，同比仅下降0.4%。一线城市中，上海平均租金同比持平，其余三城租金同比均有不同程度下跌。杭州租金价格为每月每平方米52.7元，南京、武汉、天津租金超过每月每平方米40元，其他城市的租金价格多在每月每平米20元至40元之间。
              </p>
              <p>
                以租金为例，如果租金上涨很快，租房者需要支付的租金与购房支出差别不大，很多居民就只能被迫选择购房。应该说，不少居民本身就对租房不是很有兴趣，都倾向于购房，而最能够吸引居民租房的就是租金。只有租房的性价比很高，比购房来得划算，居民才有可能选择租房，而不会选择购房。这也意味着，必须把租金控制在一定水平，这个水平到底是多少，有关方面应当组织专业人员进行测算，使其比购房有较高的性价比。
              </p>
              <p>
                以房源信息为例，如果仅仅依靠一些中介机构提供房源信息，很难保证不会出现虚假信息，进而出现房屋质量信息不真实的现象和损害租房者利益的行为。必须对租房市场进行有力整治，确保房源信息真实可靠，确保不存在虚假信息等方面的问题。
              </p>
              <p>
                发展住房租赁市场，需要具有一定规模的开发企业、主要租房机构和政府专门职能机构履行职责，而不能过快地放开市场。在各项制度、法规不够健全的情况下，盲目放开住房租赁市场，无异于制造浑水摸鱼的格局，让劣币大量进入驱逐良币。只有当市场比较健康、规范，各项制度、法规比较完备，才能逐渐放开市场，通过良币驱赶劣币让市场越来越健康、规范，使之成为房地产市场的重要组成部分。
              </p>
              <p>
                一旦租房比例达到5成以上甚至更高，房地产市场的稳定性就会越来越好，居民住房问题就能够从根本上得到解决。
              </p>
              <p>
                网民“许小乐”表示，租赁市场作为住房市场的补充，可以解决中低收入群体的居住需求，有效缓解住房市场的供需矛盾，稳定发展租赁市场的意义毋庸赘言。网民“香康”认为，六省市集中整治住房租赁市场，是对房地产市场供给侧进行改革与调整、优化与完善，不仅要让房地产市场秩序更加规范，促进房地产市场健康有序发展，也要通过供给侧水平的提高，平衡购房比重，让房地产市场从调控转向长效管理。
              </p>
              <p>
                网民“董希淼”指出，目前尚无对住房租赁机构的统一管理规定，对中介机构联合信托公司等发放的租房贷款要求也不明确，对网贷平台发放的“租金贷”等管理存在空白。建议规范住房租赁信贷行为，加强住房租赁信贷审慎经营。
              </p>
              <p>
                不少网民认为，住房租赁立法工作须按下“快进键”。网民“苏榭”称，针对虚假房源、随意涨租、租金贷等问题的整治，不能止于运动式的行政执法，更要从健全立法体系的角度，完善租赁市场法律法规。
              </p>
              <p>
                网民“汪昌莲”建议，一方面，通过整顿租赁市场乱象、稳定租金、加大租赁房源供应等，为“房住不炒”护航。另一方面，可以借鉴国外的经验，通过立法调控租金标准，规范住房租赁市场，引导市民理性居住，也可以借鉴北京等城市的做法，大力推进保障性住房建设，通过公租房、廉租房等，让民众更有获得感。
              </p>
            </div>
          </el-col>
          <el-col :span="5" class="right">
            <div class="recommendTitle">推荐</div>
            <div class="recommendList">
              <div class="recommendItem">
                <router-link to="/help1">
                  <img src="../../assets/images/help/help1.jpeg" alt="" />
                  <div class="msg">
                    房屋装修注意事项(菜鸟看后装修无遗憾)--水电安装注意事项
                  </div>
                </router-link>
              </div>
              <div class="recommendItem">
                <router-link to="/help2">
                  <img src="../../assets/images/help/help3.jpeg" alt="" />
                  <div class="msg">租房也可变Dream House</div></router-link
                >
              </div>
              <div class="recommendItem">
                <router-link to="/help3">
                  <img src="../../assets/images/help/help2.png" alt="" />
                  <div class="msg">
                    房东利益如何最大化之合理装修
                  </div></router-link
                >
              </div>
              <div class="recommendItem">
                <router-link to="/help4">
                  <img src="../../assets/images/help/help4.png" alt="" />
                  <div class="msg">自建房的房屋租赁税计算</div></router-link
                >
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="less" scoped>
#information {
  .top {
    position: relative;
    top: 0;
    left: 0;
    height: 460px;
    width: 100%;
    background-image: url(../../assets/images/information/资讯背景.png);
    background-size: cover;
  }
  .news {
    .title {
      padding: 12px 0;
      margin-top: 20px;
      text-align: left;
      color: rgba(255, 255, 255, 0.7);
      border-bottom: 1px solid #d2d2d2;
      a {
        color: #5a5e66;
      }
    }
    .content {
      padding-bottom: 50px;
      .left {
        margin-top: 25px;
        .news_title {
          color: #000;
          overflow: hidden;
          .newsDate {
            float: left;
            width: 55px;
            height: 55px;
            background-color: #436993;
            color: #fff;
            padding: 6px 2px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            text-align: center;
            .year {
              font-size: 12px;
            }
            .day {
              font-size: 28px;
            }
          }
        }
        .newsText {
          height: 55px;
          line-height: 55px;
          padding-left: 70px;
          font-size: 20px;
          background-color: #f1f3f6;
        }
        .news_content {
          text-align: left;
          color: #000;
          .bold {
            font-weight: 700;
            font-size: 18px;
          }
          p {
            font-size: 16px;
            font-weight: 300;
            color: rgb(51, 51, 51);
            text-align: justify;
            line-height: 24px;
          }
        }
      }
      .right {
        margin: 25px 0 0 50px;
        img {
          width: 200px;
          height: 115px;
        }
        background: #f2f6fa;
        min-height: 300px;
        .recommendTitle {
          color: #000;
          text-align: left;
          font-weight: bold;
          padding: 0 10px;
          margin: 20px 0;
          border-left: 3px solid #3b6798;
        }
        .recommendList {
          padding: 0 0 10px;
          .recommendItem {
            padding: 0 10%;
            margin-bottom: 15px;
            .msg {
              color: #717171;
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
}
</style>
